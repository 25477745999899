import { useEffect, useState } from 'react';

import { get, isEmpty, isNil, omit } from 'lodash';
import { Button, Message, Modal } from 'semantic-ui-react';

import { uploadSingleFileToStorage } from '../../api';
import minusIcon from '../../assets/minus.svg';
import plusIcon from '../../assets/plus.svg';
import BookingConfirm from '../../components/BookingConfirm';
import ContentEditor from '../../components/ContentEditor';
import RenderInputs from '../../components/RenderInputs';
import RenderUserDetails from '../../components/RenderUserDetails';
import TermsCheckBox from '../../components/TermsCheckBox';
import TextCopy from '../../components/TextCopy/TextCopy';
import {
  BOOKING_COLLECTION_CURRENT_EVENT,
  EVENT_YEAR,
  OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
  REDUX_PATHS_CURRENT_USER,
} from '../../configs';
import { ContentContainer, DataBox, WhiteBox } from '../../styledComponents';
import { isCurrentCountMax } from '../../utils';
import getSwedishFormatNumber from '../../utils/getSwedishFormatNumber';
import priceOfProductBookings from '../../utils/priceOfProductBookings';
import {
  defaultFormFields,
  foodtruckFormInputs,
  torghandelFormInputs,
} from './formFields';
import { ProductBookingsContainer } from './styles';

const defaultState = {
  kopvillkor: '',
  workManInfo: '',
  godkannKopvillkorOchBekraftelse: false,
  godkannJarvaVekanKod: false,
  submitting: false,
  showBookingConfirm: false,
  currentProduct: {},
  step: 1,
};

function ProductBookings(props) {
  const [state, setState] = useState(defaultState);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formError, setFormError] = useState(false);

  const collection = BOOKING_COLLECTION_CURRENT_EVENT.productBookings;
  const path = REDUX_PATHS_CURRENT_USER.currentUserProductBookings;

  const {
    firestore,
    currentUserOrganisation,
    authId,
    product, // object required,
    currentUser,
    stats,
  } = props;

  // console.log('ProductBookings: props', props);

  // console.log('ProductBookings: product', product);
  // console.log('ProductBookings: stats', stats);

  // if step changes then we scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.step]);

  if (isEmpty(product)) {
    console.info('empty product', product);
    return (
      <ProductBookingsContainer>
        <ContentContainer>
          <section>
            <WhiteBox>
              <h1>Product is not added</h1>
            </WhiteBox>
          </section>
        </ContentContainer>
      </ProductBookingsContainer>
    );
  }

  const { productId, pris, title } = product || {};

  const { maxQuantity, max, count } = stats?.[productId] || {};

  // console.log('ProductBookings:', stats?.[productId]);

  let formFields = [];

  switch (productId) {
    case 'foodtruck':
      formFields = foodtruckFormInputs;
      break;
    case 'torghandel':
      formFields = torghandelFormInputs;
      break;
    default:
      formFields = defaultFormFields;
      break;
  }

  const { error, showBookingConfirm, step } = state;

  const handleCheckboxChange = (_, { name, value, type, checked }) => {
    const localValue = type === 'checkbox' ? checked : value;

    setState((prevState) => ({ ...prevState, [name]: localValue }));
  };

  const handleNextButtonClick = (data) => {
    setState((prevState) => ({
      ...prevState,
      currentProduct: { ...data, ...state },
      step: 2,
    }));
  };

  // #region handleSubmit
  const handleSubmit = async () => {
    setState((prevState) => ({ ...prevState, submitting: true }));

    const { organizerName, orgName } = currentUserOrganisation || {};
    const currentProduct = get(state, 'currentProduct');

    if (!currentProduct) {
      return 'empty product';
    }

    const omitState = [
      'step',
      'kopvillkor',
      'workManInfo',
      'modalOpen',
      'currentProduct',
      'submitting',
      'showBookingConfirm',
      'submitting',
      'createdAt',
      'updatedAt',
      'images',
      'showInBookingPage',
    ];

    const combinedData = {
      ...state,
      ...currentProduct,
      ...priceOfProductBookings(currentProduct),
    };

    const selectedState = omit(combinedData, omitState);
    const cleanedOrganisation = omit(
      currentUserOrganisation,
      OMIT_NESTED_FIELDS_ORGANISATION_BOOKINGS,
    );

    // add to booking used for sending emails
    const { dbDocId, email, telefonnummer, firstName, secondName } =
      currentUser || {};

    const toEmails = [email];
    const ccEmails = cleanedOrganisation?.users?.filter(
      (userEmail) => userEmail !== email,
    );

    const emailsToSend = {
      to: toEmails,
      cc: ccEmails,
    };

    const bookedBy = {
      dbDocId,
      email,
      telefonnummer,
      firstName,
      secondName,
    };

    const bookingDataRaw = {
      ...selectedState,
      userId: authId,
      branchId: currentUserOrganisation.branchId,
      branchDbPath: currentUserOrganisation.dbPath,
      organisation: cleanedOrganisation,
      organizerName: organizerName || orgName,
      collection,
      sentInvoice: false,
      emailsToSend,
      bookedBy,
      createdAt: new Date().toISOString(),
    };

    const bookingData = omit(bookingDataRaw, isNil);

    firestore
      .add(
        {
          collection,
        },
        bookingData,
      )
      .then(() => {
        setState({ ...defaultState, showBookingConfirm: true, step: 1 });
      })
      .catch((err) => {
        setState((prevState) => ({
          ...prevState,
          submitting: false,
          error: err,
          step: 2,
        }));
      })
      .finally(() => {
        firestore
          .get({
            collection,
            where: [['branchId', '==', currentUserOrganisation.branchId]],
            path,
          })
          .catch((err) => {
            console.error(err);
          });
      });
  };
  // #endregion

  const renderProducts = () => {
    if (isEmpty(product)) {
      return (
        <WhiteBox>
          <h4>Produkter inte tillgängliga.</h4>
        </WhiteBox>
      );
    }

    const quantityId = `quantity_${productId}`;

    const quantity = state[quantityId] || 1;

    const isCurrentCountMaxRes = isCurrentCountMax({
      productId,
      stats,
    });

    return (
      <WhiteBox
        className="border"
        id={`scroll_to_${productId}`}
        key={productId}
      >
        <div className="media-text">
          <div>
            <h3 className="no-underline">{title}</h3>
            <ContentEditor
              contentId={`page-book-productBookings-${productId}-description`}
            >
              Add description here.
            </ContentEditor>
          </div>
          <div className="medium-image">
            <ContentEditor
              contentId={`page-user-book-productBookings-${productId}-main-1-image`}
            >
              {`
        <img
          src="https://firebasestorage.googleapis.com/v0/b/jarvaveckan-live.appspot.com/o/assets%2FPartnerskap%20-%205x5%2Fimg-10.jpg?alt=media&token=bea2cd5e-0a72-4f23-b807-7b50400f9979"
          alt="people"
          height="auto"
          width="100%"
        />
        `}
            </ContentEditor>
          </div>
        </div>
        <hr />

        <div className="price-row">
          <div>
            <p>PRIS</p>
            <div className="pris">
              <b>{getSwedishFormatNumber(pris)}</b> kr exkl.moms
            </div>
          </div>

          {/* To allow multiple selection */}
          {maxQuantity >= 2 ? (
            <div>
              <p>VÄLJ ANTAL</p>
              <div className="quantity-button-group">
                <Button
                  primary
                  className="icon-button"
                  onClick={() => {
                    setState((prevState) => {
                      const currentQuantity = prevState?.[quantityId] || 1;
                      if (currentQuantity < 2) {
                        return prevState;
                      }

                      const newQuantity = currentQuantity - 1;
                      return { ...prevState, [quantityId]: newQuantity };
                    });
                  }}
                  disabled={quantity < 2}
                >
                  <img className="img-icon" src={minusIcon} alt="remove" />
                </Button>
                <span className="count">{quantity}</span>
                <Button
                  primary
                  className="icon-button"
                  onClick={() => {
                    setState((prevState) => {
                      const newQuantity = (prevState?.[quantityId] || 1) + 1;
                      return { ...prevState, [quantityId]: newQuantity };
                    });
                  }}
                  disabled={
                    quantity >= maxQuantity ||
                    quantity >= max ||
                    count + quantity >= max
                  }
                >
                  <img className="img-icon" src={plusIcon} alt="add" />
                </Button>
              </div>
            </div>
          ) : null}

          <div>
            <Button
              primary
              className="offset-bottom-3px"
              size="large"
              floated="right"
              icon="arrow right"
              labelPosition="right"
              content={isCurrentCountMaxRes?.label || 'Nästa Steg'}
              disabled={isCurrentCountMaxRes?.isDisabled}
              onClick={() => {
                handleNextButtonClick({ ...product });
              }}
            />
          </div>
        </div>
      </WhiteBox>
    );
  };

  const renderConfirmationModal = () => {
    const { godkannKopvillkorOchBekraftelse, godkannJarvaVekanKod } = state;

    const { organizerName, orgName } = currentUserOrganisation || {};

    const currentProduct = get(state, 'currentProduct');

    if (!currentProduct) {
      return 'empty product';
    }

    const { totalPrice, quantity } = priceOfProductBookings(currentProduct);

    const { submitting } = state;

    return (
      <Modal open size="tiny" closeOnDimmerClick={false} dimmer="inverted">
        <Modal.Content>
          <WhiteBox>
            <DataBox>
              <div>
                <ul aria-label="Vänligen bekräfta dina uppgifter">
                  <li>
                    <TextCopy name="confirm-your-details" />
                  </li>
                  <li aria-label="Organisationsnamn">
                    <span className="form-like">
                      {organizerName || orgName}
                    </span>
                  </li>
                  <li aria-label="Tillägg">
                    <span className="form-like">{title}</span>
                  </li>
                  <li aria-label="Pris/Antal">
                    <span className="form-like">
                      {Number(pris).toLocaleString('sv')} kr exkl. moms
                    </span>
                  </li>
                  <li aria-label="Antal">
                    <span className="form-like">{quantity}</span>
                  </li>
                  <li aria-label="Pris">
                    <span className="form-like">
                      {Number(totalPrice).toLocaleString('sv')} kr exkl. moms
                    </span>
                  </li>
                </ul>
              </div>
              {currentUserOrganisation ? (
                <RenderUserDetails
                  currentUserOrganisation={currentUserOrganisation}
                  firestore={firestore}
                />
              ) : null}
              <TermsCheckBox handleCheckboxChange={handleCheckboxChange} />
            </DataBox>
          </WhiteBox>
        </Modal.Content>
        {error ? (
          <Message negative header="Error occurred" content={error} />
        ) : null}
        <Modal.Actions>
          <div className="action-buttons">
            <Button
              basic
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  step: 2,
                }))
              }
              primary
              content="Tillbaka"
              icon="left arrow"
              labelPosition="left"
            />
            <Button
              type="submit"
              loading={submitting}
              disabled={
                !(godkannKopvillkorOchBekraftelse && godkannJarvaVekanKod)
              }
              primary
              content="Bekräfta"
              labelPosition="right"
              icon="checkmark"
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  };

  const processFormData = (formData, formElement) => {
    const formDataObject = {};
    const formDataFiles = [];

    formData.forEach((value, name) => {
      const inputElement = formElement[name];

      if (inputElement?.type === 'checkbox') {
        formDataObject[name] = inputElement.checked;
      } else if (inputElement?.type === 'file') {
        // Convert FileList to an array of individual files if there are files
        if (inputElement.files.length !== 0) {
          Array.from(inputElement.files).forEach((file) => {
            formDataFiles.push({ name, value: file });
          });
        }
      } else if (
        inputElement?.type === 'number' &&
        value !== '' &&
        !Number.isNaN(+value)
      ) {
        formDataObject[name] = +value;
      } else if (inputElement?.nodeName === 'SELECT') {
        if (inputElement.multiple) {
          // Handle multi-select
          formDataObject[name] = Array.from(inputElement.selectedOptions).map(
            (option) => option.value,
          );
        } else {
          // Single select
          formDataObject[name] = value;
        }
      } else if (inputElement?.type === 'radio') {
        // Handle radio buttons by checking if the radio is selected
        if (inputElement.checked) {
          formDataObject[name] = value;
        }
      } else {
        formDataObject[name] = value;
      }
    });

    // Ensure all checkboxes are included
    formElement
      .querySelectorAll('input[type="checkbox"]')
      .forEach((checkbox) => {
        if (!(checkbox.name in formDataObject)) {
          formDataObject[checkbox.name] = false;
        }
      });

    return { formDataObject, formDataFiles };
  };

  const handleFileUploads = async (formDataFiles, _authId) => {
    if (!formDataFiles || formDataFiles.length === 0) {
      return;
    }

    const uploadPath = `/uploads/${EVENT_YEAR}/${_authId}`;
    const uploadPromises = formDataFiles.map(async ({ name, value }) => {
      if (!value) {
        return;
      }
      try {
        const result = await uploadSingleFileToStorage({
          file: value,
          uploadPath,
        });
        return { [name]: result };
      } catch (_error) {
        console.error(`Error uploading ${name}:`, _error);
        throw new Error(`Failed to upload ${name}`);
      }
    });

    return Promise.all(uploadPromises);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);

    try {
      const formData = new FormData(event.currentTarget);
      const { formDataObject, formDataFiles } = processFormData(
        formData,
        event.currentTarget,
      );

      let dataToSave = {
        ...formDataObject,
      };

      if (formDataFiles && formDataFiles.length > 0) {
        const uploadedImagesArray = await handleFileUploads(
          formDataFiles,
          authId,
        );

        if (uploadedImagesArray) {
          const uploadedImages = uploadedImagesArray.reduce(
            (acc, image) => ({ ...acc, ...image }),
            {},
          );

          // Merge uploadedImages with existing dataToSave
          dataToSave = {
            ...dataToSave,
            ...uploadedImages,
          };
        }
      }

      setState((prevState) => ({
        ...prevState,
        ...dataToSave,
        step: 3,
      }));
    } catch (_error) {
      console.error('Form submission error:', _error);
      setFormError(_error);
    } finally {
      setIsSubmit(false);
    }
  };

  const renderForm = () => (
    // step 2
    <Modal open size="tiny" closeOnDimmerClick={false} dimmer="inverted">
      <Modal.Content>
        <WhiteBox>
          <DataBox>
            <form onSubmit={handleFormSubmit} className="product-booking-form">
              <fieldset>
                <RenderInputs formFields={formFields} formValues={state} />
                <div className="action-buttons">
                  <Button
                    loading={isSubmit}
                    basic
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        step: 1,
                      }))
                    }
                    primary
                    content="Tillbaka"
                    icon="left arrow"
                    labelPosition="left"
                  />
                  <Button
                    loading={isSubmit}
                    primary
                    type="submit"
                    content="Nästa Steg"
                    icon="right arrow"
                    labelPosition="right"
                  />
                </div>
              </fieldset>
            </form>
            {formError && (
              <Message
                negative
                header={formError?.code || 'Error'}
                content={formError?.message || 'Check console for more info'}
              />
            )}
          </DataBox>
        </WhiteBox>
      </Modal.Content>
    </Modal>
  );
  return (
    <ProductBookingsContainer>
      <ContentContainer>
        <section>
          <WhiteBox>
            <h1>
              <ContentEditor
                inline
                contentId={`page-productBookings-${productId}-title-1`}
              >
                Title
              </ContentEditor>
            </h1>
          </WhiteBox>
          {showBookingConfirm ? <BookingConfirm /> : renderProducts()}
        </section>
      </ContentContainer>
      {step === 2 && renderForm()}
      {step === 3 && renderConfirmationModal()}
    </ProductBookingsContainer>
  );
}

export default ProductBookings;
