import { get, isEmpty, reduce, size } from 'lodash';
import { createSelector } from 'reselect';

// firebase
const getClaims = (state) => get(state, 'claims');
const getAuth = (state) => get(state, 'firebase.auth');
const getProfile = (state) => get(state, 'firebase.profile');

// FireStore Data
const getBookings = (state) => get(state, 'firestore.data.bookings');
const getTillaggBookings = (state) =>
  get(state, 'firestore.data.tillaggBookings');
const getSeminarBookings = (state) =>
  get(state, 'firestore.data.seminarBookings');
const getSeminarLiveBookings = (state) =>
  get(state, 'firestore.data.seminarLiveBookings');
const getSponsorBookings = (state) =>
  get(state, 'firestore.data.sponsorBookings');
const getCurrentUser = (state) => get(state, 'firestore.data.currentUser');
const getCurrentUserBookings = (state) =>
  get(state, 'firestore.data.currentUserBookings');
const getCurrentUserTillaggBookings = (state) =>
  get(state, 'firestore.data.currentUserTillaggBookings');
const getStats = (state) => get(state, 'firestore.data.stats');
const getContent = (state) => get(state, 'firestore.data.content', {});
const getProducts = (state) => get(state, 'firestore.data.products', {});
const getOrganisations = (state) =>
  get(state, 'firestore.data.organisations', {});
const getProductBookings = (state) =>
  get(state, 'firestore.data.productBookings', {});
const getCurrentUserSeminarsBookings = (state) =>
  get(state, 'firestore.data.currentUserSeminarsBookings');
const getCurrentUserSeminarsLiveBookings = (state) =>
  get(state, 'firestore.data.currentUserSeminarsLiveBookings');
const getCurrentUserSponsorBookings = (state) =>
  get(state, 'firestore.data.currentUserSponsorBookings');
const getCurrentUserProductBookings = (state) =>
  get(state, 'firestore.data.currentUserProductBookings');
const getCurrentUserOrganisation = (state) =>
  get(state, 'firestore.data.currentUserOrganisation');
const getCurrentUserOrganisationJoinPending = (state) =>
  get(state, 'firestore.data.currentUserOrganisationJoinPending');
const getInvitedUsers = (state) => get(state, 'firestore.data.invitedUsers');

// Computed State
const getOrderedBookings = createSelector(getBookings, (bookings) => {
  if (!bookings) return [];
  return Object.keys(bookings).map((key) => ({
    ...bookings[key],
    id: key,
  }));
});

const getOrderedTillaggBookings = createSelector(
  getTillaggBookings,
  (bookings) => {
    if (!bookings) return [];
    return Object.keys(bookings).map((key) => ({
      ...bookings[key],
      id: key,
    }));
  },
);

const getOrderedSeminarBookings = createSelector(
  getSeminarBookings,
  (bookings) => {
    if (!bookings) return [];
    return Object.keys(bookings).map((key) => ({
      ...bookings[key],
      id: key,
    }));
  },
);

const getOrderedSeminarLiveBookings = createSelector(
  getSeminarLiveBookings,
  (bookings) => {
    if (!bookings) return [];
    return Object.keys(bookings).map((key) => ({
      ...bookings[key],
      id: key,
    }));
  },
);

const getOrderedSponsorBookings = createSelector(
  getSponsorBookings,
  (bookings) => {
    if (!bookings) return [];
    return Object.keys(bookings).map((key) => ({
      ...bookings[key],
      id: key,
    }));
  },
);

const getOrderedProductBookings = createSelector(
  getProductBookings,
  (bookings) => {
    if (!bookings) return [];
    return Object.keys(bookings).map((key) => ({
      ...bookings[key],
      id: key,
    }));
  },
);

const getOrderedUserProductBookings = createSelector(
  getCurrentUserProductBookings,
  (bookings) => {
    if (!bookings) return [];
    return Object.keys(bookings).map((key) => ({
      ...bookings[key],
      id: key,
    }));
  },
);

const getOrderedInvitedUsers = createSelector(
  getInvitedUsers,
  (invitedUsers) => {
    if (!invitedUsers) return [];
    return Object.keys(invitedUsers).map((key) => ({
      ...invitedUsers[key],
      id: key,
    }));
  },
);

const getAuthId = createSelector(getAuth, (a) => a.uid);

const getTotalCurrentTentBookings = createSelector(
  getCurrentUserBookings,
  (bookings) => size(bookings),
);

const getTotalCurrentTillaggBookings = createSelector(
  getCurrentUserTillaggBookings,
  (bookings) => size(bookings),
);

const getTotalCurrentSeminarBookings = createSelector(
  getCurrentUserSeminarsBookings,
  (bookings) => size(bookings),
);

const getTotalCurrentSeminarLiveBookings = createSelector(
  getCurrentUserSeminarsLiveBookings,
  (bookings) => size(bookings),
);

const getTotalCurrentSponsorBookings = createSelector(
  getCurrentUserSponsorBookings,
  (bookings) => size(bookings),
);

const getTotalCurrentProductBookings = createSelector(
  getCurrentUserProductBookings,
  (bookings) => size(bookings),
);

// Function to filter organisations by status in a single loop
const filterOrganisationsByStatuses = (organisations) => {
  if (isEmpty(organisations))
    return { pendingOrganisations: [], approvedOrganisations: [] };

  return reduce(
    organisations,
    (acc, org) => {
      const orgList = get(org, 'orgList', []);
      orgList.forEach((orgItem) => {
        if (get(orgItem, 'isOrgPending', false)) {
          acc.pendingOrganisations.push(orgItem);
        }
        if (get(orgItem, 'isOrgApproved', false)) {
          acc.approvedOrganisations.push(orgItem);
        }
      });
      return acc;
    },
    { pendingOrganisations: [], approvedOrganisations: [] },
  );
};

const branchIdWithOrgNr = ({ orgNr, branchId }) => `${orgNr}__${branchId}`;

let timeId = null;

const logState = (state) => {
  if (timeId) {
    clearTimeout(timeId);
  }
  timeId = setTimeout(() => {
    console.info('logState: firestore', state.firestore);
  }, 200);
};

export {
  branchIdWithOrgNr,
  filterOrganisationsByStatuses,
  getAuth,
  getAuthId,
  getBookings,
  getClaims,
  getContent,
  getCurrentUser,
  getCurrentUserBookings,
  getCurrentUserOrganisation,
  getCurrentUserOrganisationJoinPending,
  getCurrentUserProductBookings,
  getCurrentUserSeminarsBookings,
  getCurrentUserSeminarsLiveBookings,
  getCurrentUserSponsorBookings,
  getCurrentUserTillaggBookings,
  getInvitedUsers,
  getOrderedBookings,
  getOrderedInvitedUsers,
  getOrderedProductBookings,
  getOrderedSeminarBookings,
  getOrderedSeminarLiveBookings,
  getOrderedSponsorBookings,
  getOrderedTillaggBookings,
  getOrderedUserProductBookings,
  getOrganisations,
  getProductBookings,
  getProducts,
  getProfile,
  getSeminarBookings,
  getSeminarLiveBookings,
  getSponsorBookings,
  getStats,
  getTillaggBookings,
  getTotalCurrentProductBookings,
  getTotalCurrentSeminarBookings,
  getTotalCurrentSeminarLiveBookings,
  getTotalCurrentSponsorBookings,
  getTotalCurrentTentBookings,
  getTotalCurrentTillaggBookings,
  logState,
};
