import { Fragment, memo, useCallback, useEffect, useState } from 'react';

import {
  constant,
  get,
  groupBy,
  isEmpty,
  map,
  reduce,
  size,
  times,
} from 'lodash';
import { isLoaded } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';

import SeminarStats from '../../components/SeminarStats';
import SpinnerLoader from '../../components/SpinnerLoader';
import { INITIAL_LOADING_TIME } from '../../configs';
import { ContentContainer, DataBox, WhiteBox } from '../../styledComponents';
import { AdminHomeContainer } from './adminHomeStyles';
import RenderBookings from './RenderBookings';

const AdminHome = ({
  users,
  branchData,
  bookings,
  tillaggBookings,
  productBookings,
  sponsorBookings,
  seminarBookings,
  seminarLiveBookings,
  stats,
  products,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  // Handle loading state with cleanup
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), INITIAL_LOADING_TIME);
    return () => clearTimeout(timer);
  }, []);

  const [state, setState] = useState({
    modalOpen: false,
    modalContent: null,
  });

  const { modalOpen, modalContent } = state;

  const maxSeminars =
    reduce(
      get(stats, 'seminars.availableTimeSlots', []),
      (acc, curr) => curr.reduce((acc2, curr2) => acc2 + curr2, acc),
      0,
    ) || 1;

  const maxSeminarsLive =
    reduce(
      get(stats, 'seminarsLive.availableTimeSlots', []),
      (acc, curr) => curr.reduce((acc2, curr2) => acc2 + curr2, acc),
      0,
    ) || 1;

  const allTents = { ...bookings };

  const tentsPerPeriod = {
    tent2_5x2_5_P1: [],
    tent2_5x2_5_P2: [],
    tent5x2_5_P1: [],
    tent5x2_5_P2: [],
    tent5x5_P1: [],
    tent5x5_P2: [],
  };

  const tentsPerPeriodTotal = {
    totalPeriod1_tent2_5x2_5: 0,
    totalPeriod2_tent2_5x2_5: 0,
    totalPeriod1_tent5x2_5: 0,
    totalPeriod2_tent5x2_5: 0,
    totalPeriod1_tent5x5: 0,
    totalPeriod2_tent5x5: 0,
  };

  const p1MaxTent2_5x2_5 = get(stats, 'tent2_5x2_5.p1Max');
  const p2MaxTent2_5x2_5 = get(stats, 'tent2_5x2_5.p2Max');
  const p1MaxTent5x2_5 = get(stats, 'tent5x2_5.p1Max');
  const p2MaxTent5x2_5 = get(stats, 'tent5x2_5.p2Max');
  const p1MaxTent5x5 = get(stats, 'tent5x5.p1Max');
  const p2MaxTent5x5 = get(stats, 'tent5x5.p2Max');

  const { sponsor: sponsorBookings1, sponsor2: sponsorBookings2 } =
    groupBy(sponsorBookings, 'productId') || {};

  const maxSponsor = get(stats, 'sponsor.max');
  const totalSponsor1 = size(sponsorBookings1);
  const maxSponsor2 = get(stats, 'sponsor2.max');
  const totalSponsor2 = size(sponsorBookings2);

  if (isLoaded(bookings) && allTents) {
    Object.values(allTents).forEach((item) => {
      if (isEmpty(item)) return;

      const { productId, quantity, period1, period2 } = item || {};

      if (period1) {
        const selectId = `${productId}_P1`;
        tentsPerPeriod[selectId].push(item);

        const totalId = `totalPeriod1_${productId}`;
        tentsPerPeriodTotal[totalId] =
          (tentsPerPeriodTotal[totalId] || 0) + +quantity;
      }

      if (period2) {
        const selectId = `${productId}_P2`;
        tentsPerPeriod[selectId].push(item);

        const totalId = `totalPeriod2_${productId}`;
        tentsPerPeriodTotal[totalId] =
          (tentsPerPeriodTotal[totalId] || 0) + +quantity;
      }
    });
  }

  const renderModel = useCallback(
    () => (
      <Modal
        dimmer="inverted"
        open={modalOpen}
        closeOnDimmerClick
        closeOnDocumentClick
        size="large"
        style={{ maxWidth: 400 }}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            modalContent: null,
            modalOpen: false,
          }))
        }
      >
        <Modal.Content scrolling>
          <WhiteBox style={{ padding: '10px', margin: 0 }}>
            <DataBox>{modalContent}</DataBox>
          </WhiteBox>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                modalContent: null,
                modalOpen: false,
              }))
            }
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    ),
    [modalOpen, modalContent],
  );

  const renderStats = useCallback(
    () => (
      <ul>
        <li className="flex column">
          <div className="label">
            <h6>Organisationer: </h6>
            {size(branchData)}
          </div>
          <div className="label">
            <h6>Users: </h6>
            {size(users)}
          </div>
        </li>
        <li>
          <div className="label">
            <h6>Bokningar: </h6>
            <ul className="extra">
              <li>
                Seminarietider: ({size(seminarBookings) || 0} av {maxSeminars})
              </li>
              <li>
                Seminarietider Live: ({size(seminarLiveBookings) || 0} av{' '}
                {maxSeminarsLive})
              </li>
              <li>
                Stödorganisation: ({totalSponsor1 || 0} av {maxSponsor})
              </li>
              <li>
                Partner: ({totalSponsor2 || 0} av {maxSponsor2})
              </li>
            </ul>
          </div>
        </li>
      </ul>
    ),
    [
      branchData,
      users,
      seminarBookings,
      maxSeminars,
      seminarLiveBookings,
      maxSeminarsLive,
      totalSponsor1,
      maxSponsor,
      totalSponsor2,
      maxSponsor2,
    ],
  );

  const renderSeminars = useCallback(
    () => (
      <>
        <ul>
          <li style={{ border: 0, paddingBottom: 0 }}>
            <br />
            <br />
            <br />
            <h4>Bokade seminarietider</h4>
          </li>
        </ul>
        <SeminarStats
          seminars={{ ...seminarBookings }}
          {...get(products, 'seminars')}
          available={{ ...get(stats, 'seminars.availableTimeSlots', []) }}
          updateModal={(stateToUpdate) =>
            setState((prevState) => ({ ...prevState, ...stateToUpdate }))
          }
        />
      </>
    ),
    [seminarBookings, products, stats],
  );

  const renderSeminarsLive = useCallback(
    () => (
      <>
        <ul>
          <li style={{ border: 0, paddingBottom: 0 }}>
            <h4>Bokade seminarietider Live</h4>
          </li>
        </ul>
        <SeminarStats
          seminars={{ ...seminarLiveBookings }}
          {...get(products, 'seminarsLive')}
          available={{ ...get(stats, 'seminarsLive.availableTimeSlots', []) }}
          updateModal={(stateToUpdate) =>
            setState((prevState) => ({ ...prevState, ...stateToUpdate }))
          }
        />
      </>
    ),
    [seminarLiveBookings, products, stats],
  );

  const renderButtons = useCallback(
    () => (
      <div className="flex no-gap">
        <Button
          primary
          basic
          icon={{ name: 'home' }}
          labelPosition="right"
          content="Verifiera ansökningar"
          onClick={() => navigate('/admin/applicants')}
        />
        <Button
          primary
          basic
          icon={{ name: 'calendar' }}
          labelPosition="right"
          content=" Se i registret"
          onClick={() => navigate('/admin/organisation')}
        />
        <Button
          primary
          basic
          icon={{ name: 'setting' }}
          labelPosition="right"
          content="Inställningar"
          onClick={() => navigate('/admin/settings')}
        />
      </div>
    ),
    [navigate],
  );

  const renderTillaggBookings = useCallback(() => {
    if (isEmpty(tillaggBookings)) return null;

    const tillaggBookingsGroupedById = groupBy(tillaggBookings, 'productId');

    const renderProductListBookings = (item, productMax) => {
      return map(item, (item2, key2) => {
        const { quantity = 1, title, userId, organizerName } = item2 || {};

        const output = [];

        for (let i = 0; i < quantity; i += 1) {
          output.push(
            <div
              key={`${key2}-${i}`}
              role="button"
              tabIndex={0}
              className="tent"
              onClick={() => {
                if (!item2) return;
                const content = (
                  <div>
                    <ul aria-label="Bokning">
                      <li
                        aria-label={`Tillägg (${get(item2, 'quantity', 1)} st)`}
                      >
                        {title}
                      </li>
                      <li aria-label="Organisationsnamn">{organizerName}</li>
                      <li aria-label="User Id">{userId}</li>
                      <li aria-label="Quantity">{`${get(item2, 'quantity', 1)}`}</li>
                      <li aria-label="Pris">
                        {Number(get(item2, 'pris', '')).toLocaleString('sv')} kr
                        exkl. moms
                      </li>
                      <li aria-label="Total Pris">
                        {Number(get(item2, 'totalPrice', '')).toLocaleString(
                          'sv',
                        )}{' '}
                        kr exkl. moms
                      </li>
                    </ul>
                  </div>
                );
                setState((prevState) => ({
                  ...prevState,
                  modalOpen: true,
                  modalContent: content,
                }));
              }}
            />,
          );
        }

        times(productMax - quantity, constant(null))?.forEach((a, i) => {
          output.push(<div className="tent empty" key={i} />);
        });

        return output;
      });
    };

    const renderProductList = map(tillaggBookingsGroupedById, (item, key) => {
      const { title } = item[0] || {};

      const currentProduct = get(stats, key);
      const { max, count } = currentProduct || {};

      return (
        <Fragment key={key}>
          <h5>{title}</h5>
          <p>Product ID= {key}</p>
          <p>Maximun allowed= {max}</p>
          <p>Current booked= {count}</p>
          {renderProductListBookings(item, max)}
        </Fragment>
      );
    });

    return (
      <>
        <ul>
          <li style={{ border: 0, paddingBottom: 0 }}>
            <h4>Bokade Tillägg</h4>
          </li>
        </ul>
        {renderProductList}
      </>
    );
  }, [stats, tillaggBookings]);

  const renderproductBookings = useCallback(() => {
    if (isEmpty(productBookings)) return null;

    const productBookingsGroupedById = groupBy(productBookings, 'productId');

    const renderProductListBookings = (item, productMax) => {
      return map(item, (item2, key2) => {
        const { quantity = 1, title, userId, organizerName } = item2 || {};

        const output = [];

        for (let i = 0; i < quantity; i += 1) {
          output.push(
            <div
              key={`${key2}-${i}`}
              role="button"
              tabIndex={0}
              className="tent"
              onClick={() => {
                if (!item2) return;
                const content = (
                  <div>
                    <ul aria-label="Bokning">
                      <li
                        aria-label={`Tillägg (${get(item2, 'quantity', 1)} st)`}
                      >
                        {title}
                      </li>
                      <li aria-label="Organisationsnamn">{organizerName}</li>
                      <li aria-label="User Id">{userId}</li>
                      <li aria-label="Quantity">{`${get(item2, 'quantity', 1)}`}</li>
                      <li aria-label="Pris">
                        {Number(get(item2, 'pris', '')).toLocaleString('sv')} kr
                        exkl. moms
                      </li>
                      <li aria-label="Total Pris">
                        {Number(get(item2, 'totalPrice', '')).toLocaleString(
                          'sv',
                        )}{' '}
                        kr exkl. moms
                      </li>
                    </ul>
                  </div>
                );
                setState((prevState) => ({
                  ...prevState,
                  modalOpen: true,
                  modalContent: content,
                }));
              }}
            />,
          );
        }

        times(productMax - quantity, constant(null))?.forEach((a, i) => {
          output.push(<div className="tent empty" key={i} />);
        });

        return output;
      });
    };

    const renderProductList = map(productBookingsGroupedById, (item, key) => {
      const { title } = item[0] || {};

      const currentProduct = get(stats, key);
      const { max, count } = currentProduct || {};

      return (
        <Fragment key={key}>
          <h5>{title}</h5>
          <p>Maximun allowed= {max}</p>
          <p>Current booked= {count}</p>
          {renderProductListBookings(item, max)}
        </Fragment>
      );
    });

    return (
      <>
        <ul>
          <li style={{ border: 0, paddingBottom: 0 }}>
            <h4>Bokade Products</h4>
          </li>
        </ul>
        {renderProductList}
      </>
    );
  }, [stats, productBookings]);

  if (isLoading) {
    return <SpinnerLoader />;
  }

  return (
    <ContentContainer>
      <section className="pink">
        <WhiteBox>
          <h2>Välkommen till Järvaveckans admin!</h2>
          <hr className="h5" />
          <AdminHomeContainer>
            {renderStats()}
            <br />
            <br />
            <ul>
              <li>
                <h4>Tältyta period 1</h4>
              </li>
              <li>
                <h4>Tältyta period 2</h4>
              </li>
            </ul>

            <RenderBookings
              {...{
                setState,
                size: '2,5x2,5',
                p1Max: p1MaxTent2_5x2_5,
                p2Max: p2MaxTent2_5x2_5,
                tentsPerPeriod1: tentsPerPeriod.tent2_5x2_5_P1,
                tentsPerPeriod2: tentsPerPeriod.tent2_5x2_5_P2,
                tentsPerPeriod1Total:
                  tentsPerPeriodTotal.totalPeriod1_tent2_5x2_5,
                tentsPerPeriod2Total:
                  tentsPerPeriodTotal.totalPeriod2_tent2_5x2_5,
              }}
            />

            <RenderBookings
              {...{
                setState,
                size: '5x2,5',
                p1Max: p1MaxTent5x2_5,
                p2Max: p2MaxTent5x2_5,
                tentsPerPeriod1: tentsPerPeriod.tent5x2_5_P1,
                tentsPerPeriod2: tentsPerPeriod.tent5x2_5_P2,
                tentsPerPeriod1Total:
                  tentsPerPeriodTotal.totalPeriod1_tent5x2_5,
                tentsPerPeriod2Total:
                  tentsPerPeriodTotal.totalPeriod2_tent5x2_5,
              }}
            />

            <RenderBookings
              {...{
                setState,
                size: '5x5',
                p1Max: p1MaxTent5x5,
                p2Max: p2MaxTent5x5,
                tentsPerPeriod1: tentsPerPeriod.tent5x5_P1,
                tentsPerPeriod2: tentsPerPeriod.tent5x5_P2,
                tentsPerPeriod1Total: tentsPerPeriodTotal.totalPeriod1_tent5x5,
                tentsPerPeriod2Total: tentsPerPeriodTotal.totalPeriod2_tent5x5,
              }}
            />

            <RenderBookings
              {...{
                title: 'Stödorganisation ',
                setState,
                size: '5x5',
                p1Max: maxSponsor,
                p2Max: maxSponsor,
                tentsPerPeriod1: sponsorBookings1,
                tentsPerPeriod2: sponsorBookings1,
                tentsPerPeriod1Total: totalSponsor1,
                tentsPerPeriod2Total: totalSponsor1,
              }}
            />

            <RenderBookings
              isSponsor
              {...{
                title: 'Partner ',
                setState,
                size: '5x5',
                p1Max: maxSponsor2,
                p2Max: maxSponsor2,
                tentsPerPeriod1: sponsorBookings2,
                tentsPerPeriod2: sponsorBookings2,
                tentsPerPeriod1Total: totalSponsor2,
                tentsPerPeriod2Total: totalSponsor2,
              }}
            />
            {renderTillaggBookings()}
            {renderproductBookings()}
            {renderSeminars()}
            {renderSeminarsLive()}
          </AdminHomeContainer>
          {renderButtons()}
        </WhiteBox>
      </section>
      {renderModel()}
    </ContentContainer>
  );
};

export default memo(AdminHome);
