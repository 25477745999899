import styled from 'styled-components';

import { vars } from '../../styledComponents';

const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 0;

    thead {
      text-transform: capitalize;
      position: relative;

      border-bottom: 1px solid ${vars.jarvaBlue};

      th {
        cursor: pointer;
        color: ${vars.jarvaBlue};
        font-size: 0.8em;
        letter-spacing: 0.8px;
        position: relative;
        border-bottom: 0px;

        &:hover {
          background-color: ${vars.jarvaBlueLight};
        }

        &::after {
          content: '';
          border-top: 2px solid ${vars.jarvaBlue};
          border-right: 2px solid ${vars.jarvaBlue};
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%) rotate(135deg);
          height: 7px;
          width: 7px;
          transition: all 0.2s ease;
          opacity: 0;
          @media ${vars.desktopAndUp} {
            right: 14px;
          }
        }

        &.sorted {
          position: relative;
          background-color: rgba(226, 170, 204, 0.1);
          color: ${vars.jarvaBlue} !important;

          &::after {
            opacity: 1;
          }
        }

        &.reversed {
          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    tr:nth-child(even) {
      background-color: rgb(247, 248, 252);
    }

    td,
    th {
      min-width: 12ch;
      max-width: 20ch;
      padding: 0.8rem 1rem;
    }

    td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 0px;
      transition: all 0.3s ease;

      &.hasClick {
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.06);
        }
      }

      &:last-child {
        border-right: 0px solid #fff;
      }
    }

    td:first-child {
      color: ${vars.jarvaBlue};
      font-weight: bold;
    }

    tbody {
      tr:last-child {
        border-bottom: 1px solid ${vars.jarvaBlue};
      }
    }

    tfoot {
      td,
      th {
        padding: 1rem 28px 2rem 10px;
        font-weight: normal;
        font-size: 0.8em;
        color: ${vars.jarvaBlue};
        font-weight: 700;
      }
    }
  }
`;

const PaginationContainer = styled.div`
  /* Pagination Styles */
  .pagination-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    padding: 1rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);

    @media ${vars.tabletAndUp} {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .pagination-info {
    font-size: 0.9rem;
    color: ${vars.jarvaBlue};
    margin-bottom: 1rem;

    @media ${vars.tabletAndUp} {
      margin-bottom: 0;
    }
  }

  .pagination-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;

    @media ${vars.tabletAndUp} {
      margin-bottom: 0;
    }
  }

  .pagination-button {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
    color: ${vars.jarvaBlue};

    &:hover:not(:disabled) {
      background-color: ${vars.jarvaBlueLight};
      border-color: ${vars.jarvaBlue};
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.active {
      background-color: ${vars.jarvaBlue};
      color: white;
      border-color: ${vars.jarvaBlue};
    }
  }

  .rows-per-page {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    label {
      font-size: 0.9rem;
      color: ${vars.jarvaBlue};
    }

    select {
      padding: 0.4rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      background-color: white;
      color: ${vars.jarvaBlue};
      font-size: 0.9rem;
      cursor: pointer;

      &:focus {
        outline: none;
        border-color: ${vars.jarvaBlue};
      }
    }
  }
`;

export { PaginationContainer, TableContainer };
