import { memo, useState } from 'react';

import { isEmpty, isEqual, omit } from 'lodash';
import { Button, Form, Grid, Message, Modal } from 'semantic-ui-react';

import ContentEditor from '../../components/ContentEditor';
import { IosContactOutline } from '../../components/Icons';
import {
  FB_COLLECTIONS,
  OMIT_NESTED_FIELDS_USER,
  REDUX_PATHS_CURRENT_USER,
} from '../../configs';
import {
  ContentContainer,
  DataBox,
  PasswordError,
  WhiteBox,
} from '../../styledComponents';
import { clearDateBeforeSaving } from '../../utils/clearDateBeforeSaving';
import Wrapper from './style';

// link: http://localhost:1234/user/user-account

const INITIAL_STATE = {
  readOnlyCurrentUser: true,
  isDeleteClicked: false,
  isSaveClicked: false,
  uploading: false,
  password: '',
  repeatPassword: '',
  submittingPassword: false,
  disableInput: false,
  isPasswordSuccess: false,
  email: '',
  oldPassword: '',
  cosponsors: {},
  loading: false,
  error: null,
};

const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (event) => {
    const { name, value, files, checked } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: files || checked || value,
    }));
  };

  return [values, handleChange];
};

const UserAccount = (props) => {
  const { currentUser, firebase, firestore, isFromUser = true } = props;
  const {
    userId = '',
    id = '',
    dbDocId = '',
    fullName = '',
    email = '',
    titel = '',
    telefonnummer = '',
    secondName = '',
    firstName = '',
  } = currentUser || {};

  const [state, setState] = useState(INITIAL_STATE);
  const [formValues, handleInputChange] = useForm({
    fullName: fullName || `${firstName} ${secondName}`,
    email: email || '',
    titel: titel || '',
    telefonnummer: telefonnummer || '',
  });

  const {
    isSaveClicked,
    readOnlyCurrentUser,
    modalOpen,
    submittingPassword,
    password,
    repeatPassword,
    isPasswordError,
    disableInput,
    passwordError,
    oldPassword,
    isPasswordSuccess,
    loading,
    error,
  } = state;

  const toggleReadOnlyMode = (readOnlyId) => {
    if (state.isSaveClicked) {
      setState((prevState) => ({
        ...prevState,
        isSaveClicked: false,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      [readOnlyId]: !prevState[readOnlyId],
    }));
  };

  const handleOpen = () =>
    setState((prevState) => ({ ...prevState, modalOpen: true }));

  const handleClose = () =>
    setState((prevState) => ({ ...prevState, modalOpen: false }));

  const handlePasswordInputChange = ({ target: { name, value } }) =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  const changePassword = () => {
    setState((prevState) => ({
      ...prevState,
      disableInput: true,
      submittingPassword: true,
    }));

    const { password, repeatPassword, oldPassword } = state;
    const user = firebase.auth().currentUser;

    if (password !== repeatPassword) {
      setState((prevState) => ({
        ...prevState,
        passwordError: {
          code: 'Password not right',
          message: 'Lösenorden måste vara 6 tecken långa',
        },
      }));
      return;
    }

    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      oldPassword,
    );

    user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => {
        user
          .updatePassword(repeatPassword)
          .then(() => {
            setState((prevState) => ({
              ...prevState,
              isPasswordSuccess: true,
              submittingPassword: false,
            }));
            setTimeout(handleClose, 2000);
          })
          .catch((error) => {
            setState((prevState) => ({
              ...prevState,
              passwordError: error,
              isPasswordError: true,
              disableInput: false,
              submittingPassword: false,
            }));
          });
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          passwordError: error,
          isPasswordError: true,
          disableInput: false,
          submittingPassword: false,
        }));
      });
  };

  const saveToDatabase = async () => {
    const databasePath = FB_COLLECTIONS.users;
    const reduxPath = isFromUser
      ? REDUX_PATHS_CURRENT_USER.currentUser
      : databasePath;
    toggleReadOnlyMode('readOnlyCurrentUser');

    const newData = {
      ...currentUser,
      ...formValues,
    };
    const omittedData = omit(newData, OMIT_NESTED_FIELDS_USER);
    const cleanedData = clearDateBeforeSaving(omittedData);

    if (isEqual(cleanedData, currentUser)) {
      console.info('data is the same, no need to save to database');
      return;
    }

    if (isEmpty(userId)) {
      console.error('saveToDatabase:id is empty');
      // set error
      setState((prevState) => ({
        ...prevState,
        error: 'Id är tom, kontakta support',
      }));
      return;
    }

    const newDataToSave = {
      ...cleanedData,
      updatedAt: new Date().toISOString(),
    };

    try {
      await firestore.set(
        {
          collection: databasePath,
          doc: dbDocId || userId || id,
          path: reduxPath,
        },
        newDataToSave,
        { merge: true },
      );
      console.info('saved successfully');
    } catch (error) {
      console.error('Error updating Firestore:', error);
      setState((prevState) => ({
        ...prevState,
        error: error.message || 'Något gick fel, kontakta support',
      }));
    } finally {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handleCancel = () => {
    setState(() => ({
      ...INITIAL_STATE,
    }));
  };

  const renderUserDetails = () => {
    return (
      <WhiteBox key={userId}>
        <DataBox>
          <h3>
            <IosContactOutline />
            Kontouppgifter - ej synliga utåt
          </h3>
          <Form
            loading={loading}
            onSubmit={(e) => {
              e.preventDefault();
              saveToDatabase();
            }}
            error={Boolean(error)}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <ul aria-label="Kontaktuppgifter">
                    <li>
                      <Form.Input
                        label="Namn"
                        maxLength="120"
                        type="text"
                        name="fullName"
                        value={formValues.fullName}
                        readOnly={readOnlyCurrentUser}
                        onChange={handleInputChange}
                      />
                    </li>
                    <li>
                      <Form.Input
                        label="E-post (Konto)"
                        maxLength="90"
                        type="email"
                        name="email"
                        value={formValues.email}
                        readOnly
                      />
                    </li>
                    <li>
                      <Form.Input
                        label="Befattning / Titel"
                        maxLength="90"
                        type="text"
                        name="titel"
                        value={formValues.titel}
                        readOnly={readOnlyCurrentUser}
                        onChange={handleInputChange}
                      />
                    </li>
                    <li>
                      <Form.Input
                        label="Telefon"
                        maxLength="20"
                        type="text"
                        name="telefonnummer"
                        value={formValues.telefonnummer}
                        readOnly={readOnlyCurrentUser}
                        onChange={handleInputChange}
                      />
                    </li>
                  </ul>
                </Grid.Column>
              </Grid.Row>
              {error && (
                <Grid.Row>
                  <Grid.Column>
                    <Message error content={error} />
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <div className="flex no-gap">
                      {readOnlyCurrentUser ? (
                        <>
                          <Button
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleReadOnlyMode('readOnlyCurrentUser');
                            }}
                            content="Redigera uppgifter"
                            primary
                            labelPosition="right"
                            icon="edit outline"
                          />
                          <Button
                            primary
                            type="button"
                            basic
                            content="Ändra lösenord"
                            icon="pencil alternate"
                            labelPosition="right"
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpen();
                              setState((prevState) => ({
                                ...prevState,
                                disableInput: false,
                                password: '',
                                repeatPassword: '',
                              }));
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            loading={loading}
                            disabled={isSaveClicked}
                            primary
                            content="Spara"
                            labelPosition="right"
                            icon="checkmark"
                          />
                          <Button
                            basic
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCancel();
                            }}
                            primary
                            content="Avbryt"
                            icon="cancel"
                            labelPosition="right"
                          />
                        </>
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Row>
            </Grid>
          </Form>
        </DataBox>
      </WhiteBox>
    );
  };

  return (
    <Wrapper>
      <ContentContainer>
        <section className="pink">
          <WhiteBox transparent>
            <ContentEditor contentId="page-new-user-account-main-title">
              {`<h1>Mina uppgifter</h1>
              <p>
                För att redigera dina uppgifter, klicka på knappen
                <b>Redigera uppgifter</b> längst ner på sidan.
              </p>`}
            </ContentEditor>
          </WhiteBox>
          {renderUserDetails()}
        </section>
        <br />
        {modalOpen && (
          <Modal
            open={modalOpen}
            onClose={handleClose}
            closeOnDimmerClick={false}
            dimmer="inverted"
            style={{ maxWidth: '500px' }}
          >
            <Modal.Content scrolling>
              <WhiteBox>
                <DataBox>
                  <div className="flex center">
                    {isPasswordSuccess ? (
                      <Message
                        success
                        positive
                        content="Ditt lösenord har ändrats"
                      />
                    ) : (
                      <Form loading={loading}>
                        <ul aria-label="Bekräfta lösenord">
                          <li>
                            <Form.Input
                              maxLength="120"
                              type="email"
                              required
                              name="email"
                              autoComplete="username"
                              value={email || ''}
                              readOnly
                            />
                          </li>
                          <li>
                            <Form.Input
                              label="Gammalt lösenord"
                              maxLength="40"
                              onChange={handlePasswordInputChange}
                              value={oldPassword || ''}
                              required
                              type="password"
                              name="oldPassword"
                              autoComplete="password"
                            />
                          </li>
                          <li>
                            <Form.Input
                              label="Nytt lösenord"
                              type="password"
                              name="password"
                              value={password || ''}
                              readOnly={disableInput}
                              onChange={handlePasswordInputChange}
                              autoComplete="new-password"
                              suggested="new-password"
                              required
                            />
                          </li>
                          <li>
                            <Form.Input
                              label="Upprepa nytt lösenord"
                              type="password"
                              name="repeatPassword"
                              autoComplete="new-password"
                              suggested="new-password"
                              readOnly={disableInput}
                              value={repeatPassword || ''}
                              onChange={handlePasswordInputChange}
                              required
                            />
                          </li>
                        </ul>
                      </Form>
                    )}
                  </div>
                  <PasswordError isPasswordError={isPasswordError}>
                    <div>
                      {isPasswordError && (
                        <Message
                          error
                          header={passwordError.code}
                          content={passwordError.message}
                        />
                      )}
                    </div>
                  </PasswordError>
                </DataBox>
              </WhiteBox>
            </Modal.Content>
            {isPasswordSuccess ? (
              ''
            ) : (
              <Modal.Actions>
                <div className="flex no-gap">
                  <Button
                    basic
                    onClick={(e) => {
                      e.preventDefault();
                      handleClose();
                    }}
                    primary
                    content="Avbryt"
                    icon="cancel"
                    labelPosition="right"
                  />
                  <Button
                    type="submit"
                    loading={loading}
                    primary
                    content="Bekräfta"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={changePassword}
                    disabled={
                      password !== repeatPassword ||
                      password === '' ||
                      repeatPassword === '' ||
                      password.length < 6 ||
                      repeatPassword.length < 6 ||
                      oldPassword === '' ||
                      oldPassword.length < 6 ||
                      submittingPassword
                    }
                  />
                </div>
              </Modal.Actions>
            )}
          </Modal>
        )}
      </ContentContainer>
    </Wrapper>
  );
};

export default memo(UserAccount);
