import { useCallback, useEffect, useState } from 'react';

import { get, isEmpty, omit } from 'lodash';
import { Link } from 'react-router-dom';

import { uploadFileToStorage } from '../../api';
import ContentEditor from '../../components/ContentEditor';
import { ContentContainer } from '../../styledComponents';
import { clearDateBeforeSaving } from '../../utils/clearDateBeforeSaving';
import OrganisationSignUp from '../OrganisationSignUp';
import OrgUserManagement from '../OrgUserManagement';
import Wrapper from '../UserAccount/style';
import Invitations from './Invitations';
import RenderOrganisationDetails from './RenderOrganisationDetails';

// http://localhost:1234/user/org-account

const INITIAL_STATE = {
  readOnlyCurrentUserOrganisation: '',
  stateCurrentUserOrganisation: {},
  isDeleteClicked: false,
  isSaveClicked: false,
  uploading: false,
  password: '',
  repeatPassword: '',
  submittingPassword: false,
  disableInput: false,
  isPasswordSuccess: false,
  email: '',
  oldPassword: '',
  cosponsors: {},
  loading: false,
  tentsOpen: {},
  seminarOpen: {},
  seminarLiveOpen: {},
  profileOpen: false,
  userOpen: false,
  sponsorOpen: false,
  modalOpen: false,
  uploadingCurrentUserOrganisationBookings: false,
};

function OrganisationAccount(props) {
  const {
    currentUserOrganisation,
    currentUser,
    firestore,
    firebase,
    claims,
    isFromUser = true,
    registerState,
    authId,
    isFromAdminPage = false,
    currentTab = 'information',
  } = props;

  const [state, setState] = useState(INITIAL_STATE);
  const [currentUserInvites, setCurrentUserInvites] = useState({});

  const isAdmin = get(claims, 'admin', isFromAdminPage);

  const {
    isOrgApproved = false,
    isOrgRejected = false,
    isOrgPending = false,
    branchId,
    orgName,
    orgNr,
  } = currentUser || {};

  useEffect(() => {
    if (state.loading) return;

    if (
      isEmpty(state.stateCurrentUserOrganisation) &&
      !isEmpty(currentUserOrganisation)
    ) {
      setState((prev) => ({
        ...prev,
        stateCurrentUserOrganisation: { ...currentUserOrganisation },
      }));
    }
  }, [
    currentUserOrganisation,
    state.loading,
    state.stateCurrentUserOrganisation,
  ]);

  const getCurrentUserInvites = useCallback(async () => {
    if (currentUser?.email) {
      const doc = await firestore
        .collection('invites')
        .doc(currentUser.email)
        .get();

      setCurrentUserInvites(doc.data());
    }
  }, [currentUser?.email, firestore]);

  useEffect(() => {
    getCurrentUserInvites();
  }, [getCurrentUserInvites]);

  const toggleState = (stateKey, key) => {
    setState((prev) => {
      if (key) {
        return {
          ...prev,
          [stateKey]: {
            ...prev[stateKey],
            [key]: !prev[stateKey][key],
          },
        };
      }
      return {
        ...prev,
        [stateKey]: !prev[stateKey],
      };
    });
  };

  const toggleReadOnlyMode =
    (readOnlyId, branchId = '') =>
    () => {
      setState((prev) => {
        const updates = {};
        if (prev.isSaveClicked) {
          updates.isSaveClicked = false;
        }

        if (branchId && readOnlyId !== 'readOnlyCurrentUserOrganisation') {
          updates[readOnlyId] = {
            ...prev[readOnlyId],
            [branchId]: !prev[readOnlyId][branchId],
          };
        } else {
          updates[readOnlyId] = !prev[readOnlyId];
        }

        return { ...prev, ...updates };
      });
    };

  const saveToDatabase = (args) => async (e) => {
    e.preventDefault();
    const {
      dbCollection,
      branchId,
      reduxPath,
      stateDataId,
      readOnlyId,
      omitFields,
    } = args;

    let { data } = args;

    if (omitFields) {
      data = omit(args.data, omitFields);
    }

    toggleReadOnlyMode(readOnlyId, branchId)();
    const isDataChanged = state[stateDataId];

    if (dbCollection && !isEmpty(isDataChanged)) {
      setState((prev) => ({ ...prev, loading: true, isSaveClicked: true }));

      const cleanedData = clearDateBeforeSaving(data);
      const newDataToSave = {
        ...cleanedData,
        updatedAt: new Date().toISOString(),
      };

      // Handle file uploads
      const fileUploads = Object.entries(data)
        .filter(([, value]) => value instanceof FileList)
        .map(async ([name, value]) => {
          if (name === 'logo') {
            const downloadURL = await uploadFileToStorage({
              uploadPath: `uploads/company_logo/${branchId}`,
              uid: branchId,
              fileList: value,
              firebase,
            });
            return { name, downloadURL };
          }
          return null;
        });

      try {
        const uploadResults = await Promise.all(fileUploads);
        uploadResults.forEach((result) => {
          if (result && result.downloadURL) {
            newDataToSave[result.name] = result.downloadURL;
          }
        });

        await firestore.set(
          {
            collection: dbCollection,
            doc: branchId,
            path: reduxPath || dbCollection,
          },
          newDataToSave,
          { merge: true },
        );

        console.info('saved successfully');
      } catch (error) {
        console.error(error);
        setState((prev) => ({ ...prev, error }));
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    }
  };

  const handleCancel = () => {
    setState(() => ({
      ...INITIAL_STATE,
    }));
    if (registerState) registerState();
  };

  const handleInputChange = (readOnlyId) => {
    if (state.isSaveClicked) {
      setState((prev) => ({
        ...prev,
        isSaveClicked: false,
      }));
    }

    return (event) => {
      const {
        target: { name, value, files, checked },
      } = event;

      if (checked) {
        return setState((prev) => ({
          ...prev,
          [readOnlyId]: {
            ...prev[readOnlyId],
            [name]: checked,
          },
        }));
      }

      if (name === 'fullName') {
        const [firstName = '', secondName = ''] = value.trim().split(' ');
        return setState((prev) => ({
          ...prev,
          [readOnlyId]: {
            ...prev[readOnlyId],
            firstName,
            secondName,
            fullName: value,
          },
        }));
      }

      setState((prev) => ({
        ...prev,
        [readOnlyId]: {
          ...prev[readOnlyId],
          [name]: files || value,
        },
      }));
    };
  };

  // Render organisation details if user is rejected we should also redirect to information page
  // Will not get triggered as the logic is now.
  if (isOrgRejected) {
    return (
      <Wrapper>
        <ContentContainer>
          <h1>Gå med i en organisation</h1>
          <p>
            Din förfrågan om att gå med i organisationen har blivit avslaget. Om
            du vill ha tillgång till organisationen, vänligen kontakta vår
            helpdesk för assistans.
          </p>
          <Link to="/user/information">
            Information om Järvaveckan för hjälp
          </Link>
        </ContentContainer>
      </Wrapper>
    );
  }

  if (!isOrgApproved) {
    return (
      <Wrapper>
        <ContentContainer>
          <h1>Registrera eller gå med i en organisation</h1>
          <p>
            För att boka och hantera bokningar, måste du vara medlem i en
            organisation.
          </p>

          {/* accept or reject invitations */}
          <Invitations
            currentUser={currentUser}
            invites={currentUserInvites}
            getCurrentUserInvites={getCurrentUserInvites}
            authId={authId}
            firestore={firestore}
            branchId={branchId}
          />

          {/* Join organisation  or create new organisation and branch, will got through approval process */}
          {!isOrgPending ? (
            <OrganisationSignUp {...props} />
          ) : (
            <>
              <p>
                Din förfrågan om att gå med i organisationen {orgName} ({orgNr})
                behandlas för närvarande. Vi kommer att meddela dig när den har
                godkänts.
              </p>
            </>
          )}
        </ContentContainer>
      </Wrapper>
    );
  }

  const renderStack = [];

  if (isFromAdminPage || currentTab === 'information') {
    renderStack.push(
      <Wrapper key="information">
        <ContentContainer>
          <ContentEditor contentId="page-org-account-main-title">
            {`<h1>Min organisation</h1>
        <p>
          För att redigera dina uppgifter, klicka på knappen
          <b>Redigera uppgifter</b> längst ner på sidan.
        </p>`}
          </ContentEditor>
          <RenderOrganisationDetails
            isFromAdminPage={isFromAdminPage}
            currentUserOrganisation={currentUserOrganisation}
            state={state}
            isFromUser={isFromUser}
            toggleReadOnlyMode={toggleReadOnlyMode}
            handleCancel={handleCancel}
            toggleState={toggleState}
            saveToDatabase={saveToDatabase}
            isAdmin={isAdmin}
            handleInputChange={handleInputChange}
            setState={setState}
          />
        </ContentContainer>
      </Wrapper>,
    );
  }

  if (isFromAdminPage || currentTab === 'medlemmar') {
    renderStack.push(
      <Wrapper key="medlemmar">
        <ContentContainer>
          <ContentEditor contentId="page-org-account-main-title-medlemmar">
            {`<h1>Medlemmar</h1>`}
          </ContentEditor>

          {/* accept or reject invitations */}
          <Invitations
            currentUser={currentUser}
            invites={currentUserInvites}
            getCurrentUserInvites={getCurrentUserInvites}
            authId={authId}
            firestore={firestore}
            branchId={branchId}
          />

          {/* show all users in organisation, edit and delete users */}
          <OrgUserManagement
            currentUser={currentUser}
            currentUserOrganisation={currentUserOrganisation}
            isAdmin={isAdmin}
            firestore={firestore}
          />
        </ContentContainer>
      </Wrapper>,
    );
  }

  return renderStack;
}

export default OrganisationAccount;
