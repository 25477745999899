import {useEffect,useState} from 'react';

function useInternetStatus(
  timeoutMs = 3000,
  pingUrl = `${window.location.origin}/`,
) {
  const [isOnline, setIsOnline] = useState(true); // Assume online initially

  useEffect(() => {
    let timeout; // Declare timeout in the useEffect scope

    const checkConnectivity = async () => {
      const controller = new AbortController();
      timeout = setTimeout(() => controller.abort(), timeoutMs);

      try {
        const response = await fetch(pingUrl, {
          method: 'HEAD', // Minimal request
          cache: 'no-cache', // Ensure fresh check
          signal: controller.signal,
        });

        clearTimeout(timeout);

        setIsOnline(response.ok); // Update state based on response
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Connectivity check failed:', error);
        }
        setIsOnline(false); // Assume offline on failure
        clearTimeout(timeout);
      }
    };

    const handleOnline = () => checkConnectivity();
    const handleOffline = () => setIsOnline(false);

    // Initial connectivity check
    checkConnectivity();

    // Listen for online/offline events
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup on unmount
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [timeoutMs, pingUrl]);

  return isOnline;
}

export default useInternetStatus;
