import { memo, useState } from 'react';

import {
  Button,
  Confirm,
  Icon,
  Label,
  MenuItem,
  Message,
  Tab,
  TabPane,
} from 'semantic-ui-react';

import { deleteOrganisation } from '../../api/api';
import SpinnerLoader from '../../components/SpinnerLoader';
import { INITIAL_LOADING_TIME } from '../../configs';
import OrganisationAccount from '../OrganisationAccount';
import UserBookings from '../UserBookings';

const AdminAccount = (props) => {
  const {
    handleCancelPreview,
    orgNr,
    branchId,
    orgName,
    allowDelete = true,
    productBookings = 0,
    seminar = 0,
    seminarLive = 0,
    sponsor = 0,
    tent = 0,
    tillagg = 0,
  } = props;

  // console.log('AdminAccount props', props);

  const [isDeleting, setIsDeleting] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const totalBookings =
    productBookings + seminar + seminarLive + sponsor + tent + tillagg;

  const handleLoadingAfterDelete = async () => {
    setIsLoading(true);
    await new Promise((resolve) =>
      setTimeout(resolve, INITIAL_LOADING_TIME * 2),
    );
    setIsLoading(false);
  };

  // console.log('AdminAccount props', { orgNr, branchId, orgName });

  const handleDeleteDocument = async () => {
    try {
      setIsDeleting(true);
      const response = await deleteOrganisation({
        data: { orgNr, branchId, orgName },
      });
      console.info('handleDeleteDocument response', response.data);
    } catch (error) {
      console.error('handleDeleteDocument error', error);
      setError(error?.message || 'Ett fel uppstod');
    } finally {
      await handleLoadingAfterDelete();
      setIsDeleting(false);
      handleCancelPreview();
    }
  };

  const panes = [
    {
      menuItem: (
        <MenuItem key="min-organisation">
          <Icon name="users" />
          Min organisation
        </MenuItem>
      ),

      render: () => (
        <TabPane>
          <OrganisationAccount {...props} isFromAdminPage isFromUser={false} />
        </TabPane>
      ),
    },
    {
      menuItem: (
        <MenuItem key="bookings">
          <Icon name="calendar" />
          Bookings<Label>{totalBookings}</Label>
        </MenuItem>
      ),
      render: () => (
        <TabPane>
          <UserBookings {...props} isFromAdminPage isFromUser={false} />
        </TabPane>
      ),
    },
  ];

  return (
    <>
      <div>
        <Button
          type="button"
          icon="arrow left"
          labelPosition="left"
          primary
          content="Tillbaka till organisation"
          onClick={handleCancelPreview}
        />

        {totalBookings < 1 && allowDelete && orgNr && branchId && (
          <Button
            floated="right"
            type="button"
            icon="trash"
            negative
            content="Radera organisation"
            onClick={() => setOpenConfirm(true)}
            loading={isDeleting}
          />
        )}
        <Confirm
          open={openConfirm}
          onCancel={() => setOpenConfirm(false)}
          cancelButton={
            <Button
              positive
              content="Avbryt"
              onClick={() => setOpenConfirm(false)}
              autoFocus
              loading={isDeleting}
              icon="close"
            />
          }
          confirmButton={
            <Button
              negative
              icon="trash"
              content="Radera"
              onClick={handleDeleteDocument}
              loading={isDeleting}
            />
          }
          header="Radera organisation"
          content="Är du säker på att du vill radera organisationen? Detta går inte att ångra."
        />
      </div>

      {error && (
        <Message
          error={!!error}
          header="Ett fel uppstod"
          content={error}
          onDismiss={() => setError(null)}
        />
      )}
      <br />

      <Tab menu={{ attached: false }} panes={panes} />

      <SpinnerLoader isLoading={isLoading} />
    </>
  );
};

export default memo(AdminAccount);
