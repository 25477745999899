import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty, size, some } from 'lodash';
import { Button, Modal } from 'semantic-ui-react';

import AccDataTable from '../../components/AccDataTable';
import { IosCalendarOutline, MdSearch } from '../../components/Icons';
import SpinnerLoader from '../../components/SpinnerLoader';
import { INITIAL_LOADING_TIME } from '../../configs';
import { branchIdWithOrgNr } from '../../redux-config/selectors';
import { ContentContainer, WhiteBox } from '../../styledComponents';
import getOrganisationFullBookingsData from '../../utils/getOrganisationFullBookingsData';
import AdminAccount from '../AdminAccount/AdminAccount';
import { AdminOrganisationerDataBox } from './AdminOrganisationerStyles';
import exportData from './exportData';

// link: http://localhost:1234/admin/organisation
// https://boka.jarvaveckan.se/admin/organisation

const initialState = {
  search: '',
  dataFilter: [
    ['getAll', 'Alla'],
    ['hasBookings', 'Har bokningar'],
    ['hasTillagg', 'tillagg'],
    ['hasTent', 'utställare'],
    ['hasTent2_5x2_5', '2,5x2,5'],
    ['hasTent5x2_5', '5x2,5'],
    ['hasTent5x5', '5x5'],
    ['hasSponsor', 'sponsorer'],
    ['hasSeminars', 'seminariehållare'],
    ['hasSeminarslive', 'seminariehållarelive'],
    ['hasProductBookings', 'Produkter'],
  ],
  selectedDataFilter: ['getAll', 'Alla'],
  showDetailsView: false,
  selectedUserRow: {},
};

const checkHasBookings = ({
  tent,
  sponsor,
  seminar,
  seminarLive,
  tillagg,
  productBookings,
}) => {
  return (
    tent > 0 ||
    sponsor > 0 ||
    seminar > 0 ||
    seminarLive > 0 ||
    tillagg > 0 ||
    productBookings > 0
  );
};

const hasTentBookingType = (tentBookings, tentBookingType) => {
  return some(tentBookings, { productId: tentBookingType });
};

const tableFilters = {
  getAll: (item) => item,
  hasBookings: checkHasBookings,
  hasTent: ({ tent }) => tent > 0,
  hasTent2_5x2_5: ({ tentBookings }) =>
    hasTentBookingType(tentBookings, 'tent2_5x2_5'),
  hasTent5x2_5: ({ tentBookings }) =>
    hasTentBookingType(tentBookings, 'tent5x2_5'),
  hasTent5x5: ({ tentBookings }) => hasTentBookingType(tentBookings, 'tent5x5'),
  hasSponsor: ({ sponsor }) => sponsor > 0,
  hasSeminars: ({ seminar }) => seminar > 0,
  hasSeminarslive: ({ seminarLive }) => seminarLive > 0,
  hasTillagg: ({ tillagg }) => tillagg > 0,
  hasProductBookings: ({ productBookings }) => productBookings > 0,
};

const AdminOrganisationer = (props) => {
  const [state, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [fullData, setFullData] = useState({});

  // Handle loading state with cleanup
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), INITIAL_LOADING_TIME);
    return () => clearTimeout(timer);
  }, []);

  const {
    orderedBookings,
    orderedTillaggBookings,
    orderedSeminarBookings,
    orderedSeminarLiveBookings,
    orderedSponsorBookings,
    orderedProductBookings,
    firestore,
    firebase,
    claims,
    branchData,
    users,
  } = props;

  const {
    search,
    dataFilter,
    selectedDataFilter,
    showDetailsView,
    selectedUserRow,
  } = state;

  useEffect(() => {
    setFullData(
      getOrganisationFullBookingsData({
        users,
        branchData,
        orderedBookings,
        orderedTillaggBookings,
        orderedSeminarBookings,
        orderedSeminarLiveBookings,
        orderedProductBookings,
        orderedSponsorBookings,
      }),
    );
  }, [
    branchData,
    orderedBookings,
    orderedProductBookings,
    orderedSeminarBookings,
    orderedSeminarLiveBookings,
    orderedSponsorBookings,
    orderedTillaggBookings,
    users,
  ]);

  const inputChangeHandler = useCallback(({ target }) => {
    const { value } = target;
    setState((prevState) => ({ ...prevState, search: value }));
  }, []);

  const toggleFilter = useCallback(
    (item) => () => {
      setState((prevState) => ({ ...prevState, selectedDataFilter: item }));
    },
    [],
  );

  const handleCancelPreview = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      selectedUserRow: {},
      showDetailsView: false,
    }));
  }, []);

  const handleSelectedUserRowClick = useCallback((data) => {
    setState((prevState) => ({
      ...prevState,
      showDetailsView: true,
      selectedUserRow: data,
    }));
  }, []);

  const handleTableDataFilter = useCallback(
    (search = '', selectedDataFilter) =>
      (item) => {
        if (isEmpty(item)) {
          return true;
        }

        if (search) {
          const searchLowerCase = search.toLowerCase();

          const {
            orgName = '',
            organizerName = '',
            orgNr = '',
            telefonnummer = '',
            fakturamottagare = '',
            fakturaEmail = '',
            orgEmail = '',
            users = '',
          } = item;

          // Combine all searchable fields into an array
          const searchFields = [
            orgName,
            organizerName,
            orgNr,
            telefonnummer,
            fakturamottagare,
            fakturaEmail,
            orgEmail,
            ...(Array.isArray(users) ? users.join(',') : users),
          ];

          // Check if any field includes the search term
          return searchFields
            .filter(Boolean) // Remove null/undefined/false values
            .some(
              (field) => String(field).toLowerCase().includes(searchLowerCase), // Ensure each field is a string
            );
        }

        if (!isEmpty(selectedDataFilter)) {
          return tableFilters[selectedDataFilter[0]](item);
        }

        return true;
      },
    [],
  );

  const currentFilter = useMemo(
    () => handleTableDataFilter(search, selectedDataFilter),
    [handleTableDataFilter, search, selectedDataFilter],
  );

  const filterRow = useMemo(
    () =>
      dataFilter?.map((item) => (
        <div
          key={item}
          role="presentation"
          className={`filter ${selectedDataFilter === item ? 'selected' : ''}`}
          onClick={toggleFilter(item)}
        >
          {item[1]}
        </div>
      )),
    [dataFilter, selectedDataFilter, toggleFilter],
  );

  const tableColumns = useMemo(
    () => [
      ['orgName', 'Organisation namn'],
      ['organizerName', 'organizer Name'],
      ['orgNr', 'orgnr'],
      ['typeOfOrg', 'orgtyp'],
      ['tent', 'Tältyta'],
      ['seminar', 'Seminarietider'],
      ['seminarLive', 'Seminarietider Live'],
      ['sponsor', 'Partners'],
      ['tillagg', 'tillagg'],
      ['productBookings', 'Produkter'],
      ['totalCost', 'fakturabelopp'],
      ['telefonnummer', 'telefon'],
      ['fakturamottagare', 'fakturamottagare'],
      ['fakturaEmail', 'fakturaEmail'],
      ['orgEmail', 'orgEmail'],
      ['peIds', 'PE:Accounting Id'],
      ['users', 'users'],
      ['ort', 'ort'],
      ['hemsida', 'hemsida'],
    ],
    [],
  );

  const columnClick = useMemo(
    () => ({
      orgName: handleSelectedUserRowClick,
    }),
    [handleSelectedUserRowClick],
  );

  const computeFilteredUserData = useCallback(() => {
    const dataArray = Array.isArray(fullData)
      ? fullData
      : Object.values(fullData);

    const filteredArrayData = dataArray?.filter(
      handleTableDataFilter(search, selectedDataFilter),
    );

    exportData(filteredArrayData);
  }, [fullData, handleTableDataFilter, search, selectedDataFilter]);

  return (
    <>
      <ContentContainer>
        <section>
          <WhiteBox>
            <AdminOrganisationerDataBox>
              <h3>
                <IosCalendarOutline />
                Organisationer
                <div className="filters">{size(fullData)} Organisationer</div>
              </h3>
              <div>
                <Button
                  primary
                  size="mini"
                  basic
                  content="Ladda ner data"
                  icon="file excel"
                  labelPosition="right"
                  style={{ marginLeft: 'auto' }}
                  onClick={() => computeFilteredUserData()}
                />
              </div>
              <div className="horizontal-container horizontal-container-row">
                <div className="singel-flex">
                  <div className="search">
                    <MdSearch />
                    <input
                      className="search-input"
                      maxLength="140"
                      type="search"
                      placeholder="Sök organisation"
                      value={search}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </div>
                <div className="singel-flex filter-row">{filterRow}</div>
              </div>
            </AdminOrganisationerDataBox>
            <AccDataTable
              obj={fullData}
              columns={tableColumns}
              columnClick={columnClick}
              sort="orgName"
              uniqKey="branchId"
              filter={currentFilter}
            />
          </WhiteBox>
        </section>
      </ContentContainer>
      {/* Modal */}
      <Modal
        open={showDetailsView}
        closeOnDimmerClick
        closeOnDocumentClick
        onClose={handleCancelPreview}
        size="fullscreen"
        dimmer="blurring"
        centered={false}
        style={{ padding: '1rem 1rem' }}
      >
        <Modal.Header>
          {selectedUserRow?.orgName} - ({selectedUserRow?.orgNr})
        </Modal.Header>
        <Modal.Content scrolling>
          <AdminAccount
            {...fullData[branchIdWithOrgNr(selectedUserRow)]}
            firestore={firestore}
            firebase={firebase}
            claims={claims}
            handleCancelPreview={handleCancelPreview}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            icon="arrow left"
            labelPosition="left"
            primary
            content="Tillbaka till organisation"
            onClick={handleCancelPreview}
          />
        </Modal.Actions>
      </Modal>
      {/* Spinner */}
      <SpinnerLoader isLoading={isLoading} />
    </>
  );
};

export default memo(AdminOrganisationer);
