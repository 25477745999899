import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid, GridColumn } from 'semantic-ui-react';

import { ASSETS } from '../../configs/assets';
import { WhiteBox } from '../../styledComponents';
import ContentEditor from '../ContentEditor';

function BookingConfirm() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    if (path === location.pathname) {
      window.location.reload();
    } else {
      navigate(path);
    }
  };

  return (
    <WhiteBox>
      <Grid stackable columns={2}>
        <GridColumn width={10}>
          <ContentEditor contentId="page-user-booking-booking-confirm" />
          <div className="button-container">
            <Button
              type="button"
              basic
              primary
              size="large"
              content="Mina bokningar"
              icon="user circle outline"
              labelPosition="right"
              onClick={() => handleNavigation('/user/bookings')}
            />
            <Button
              type="button"
              basic
              primary
              size="large"
              content="Se övriga produkter och tjänster du kan addera till din bokning"
              icon="star outline"
              labelPosition="right"
              onClick={() => handleNavigation('/user/book/tillagg')}
            />
            <Button
              type="button"
              basic
              primary
              size="large"
              content="Boka mer"
              icon="calendar outline"
              labelPosition="right"
              onClick={() => handleNavigation('/user/book')}
            />
          </div>
        </GridColumn>
        <GridColumn width={6} floated="right">
          <div>
            <ContentEditor contentId="page-user-booking-booking-confirm-banner">
              {`<h4>Dela på sociala medier</h4><p>Ladda ner banderoll för att visa att ni är en del av Järvaveckan</p>`}
            </ContentEditor>
            <img
              src={ASSETS.arrangorBanner}
              alt="Arrangörsbanderoll"
              style={{
                maxWidth: '15rem',
                height: 'auto',
                marginBottom: '1rem',
              }}
            />
            <Button
              as="a"
              href={ASSETS.arrangorBanner}
              target="_blank"
              rel="noopener noreferrer"
              download
              basic
              primary
              fluid
              icon="download"
              content="Ladda ner"
            />
          </div>
        </GridColumn>
      </Grid>
    </WhiteBox>
  );
}

export default BookingConfirm;
