import { saveAs } from 'file-saver';
import jsonexport from 'jsonexport';
import sortBy from 'lodash/sortBy';

const sortList = (data) => sortBy(data, ['email']);

const exportData = (data, type = 'users') => {
  const minimizedSeminars = sortList(data)?.map((obj) => {
    const {
      email,
      title,
      name,
      createdAt,
      lastLogin,
      orgName,
      telefonnummer,
      branchId,
      id,
      branchDbPath,
    } = obj;

    return {
      email,
      title,
      name,
      createdAt,
      lastLogin,
      orgName,
      telefonnummer,
      branchId,
      id,
      branchDbPath,
    };
  });
  const options = {
    rowDelimiter: ';',
  };

  jsonexport(minimizedSeminars, options, (err, csv) => {
    if (err) return console.error(err);
    const csvblob = new Blob([`\ufeff${csv}`], {
      type: 'text/csv;charset=utf-8',
    });
    saveAs(csvblob, `${type}.csv`);
  });
};

export default exportData;
