import { saveAs } from 'file-saver';
import jsonexport from 'jsonexport';
import sortBy from 'lodash/sortBy';

const sortList = (data) => sortBy(data, ['orgName']);

const exportData = (data) => {
  const minimizedSeminars = sortList(data)?.map((obj) => {
    const {
      orgName,
      organizerName,
      orgNr,
      typeOfOrg,
      tent,
      seminar,
      seminarLive,
      sponsor,
      tillagg,
      productBookings,
      totalCost,
      telefonnummer,
      fakturamottagare,
      fakturaEmail,
      orgEmail,
      peIds,
      users,
      ort,
      hemsida,
    } = obj;

    return {
      orgName,
      organizerName,
      orgNr,
      typeOfOrg,
      tent,
      seminar,
      seminarLive,
      sponsor,
      tillagg,
      productBookings,
      totalCost,
      telefonnummer,
      fakturamottagare,
      fakturaEmail,
      orgEmail,
      peIds,
      users,
      ort,
      hemsida,
    };
  });
  const options = {
    rowDelimiter: ';',
  };

  jsonexport(minimizedSeminars, options, (err, csv) => {
    if (err) return console.error(err);
    const csvblob = new Blob([`\ufeff${csv}`], {
      type: 'text/csv;charset=utf-8',
    });
    saveAs(csvblob, 'organizationContact.csv');
  });
};

export default exportData;
