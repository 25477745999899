import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty, size } from 'lodash';
import { Button } from 'semantic-ui-react';

import AccDataTable from '../../components/AccDataTable';
import { IosCalendarOutline, MdSearch } from '../../components/Icons';
import SpinnerLoader from '../../components/SpinnerLoader';
import { INITIAL_LOADING_TIME } from '../../configs';
import { ContentContainer, WhiteBox } from '../../styledComponents';
import { getDateFromTimeStamp } from '../../utils/time-utils';
import { AdminOrganisationerDataBox } from '../AdminOrganisationer/AdminOrganisationerStyles';
import exportData from './exportData';

// link: http://localhost:1234/admin/users

const initialState = {
  search: '',
  dataFilter: [],
  selectedDataFilter: null,
};

const AllUsers = ({ users }) => {
  const [state, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);

  const { search, dataFilter, selectedDataFilter } = state;

  // Handle loading state with cleanup
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), INITIAL_LOADING_TIME);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!users) return;

    // Transform users data into a format suitable for the table
    const formattedUsers = Object.entries(users).map(
      ([
        id,
        {
          firstName,
          secondName,
          email,
          titel,
          createdAt,
          loggedin,
          dbDocId,
          branchDbPath,
          orgName,
          telefonnummer,
          branchId,
        },
      ]) => ({
        id,
        name: `${firstName || ''} ${secondName || ''}`.trim(),
        title: titel || '',
        email: email || '',
        createdAt: getDateFromTimeStamp(createdAt),
        lastLogin: getDateFromTimeStamp(loggedin),
        dbDocId,
        branchDbPath,
        orgName,
        telefonnummer,
        branchId,
      }),
    );

    setUserData(formattedUsers);
  }, [users]);

  const inputChangeHandler = useCallback(({ target }) => {
    setState((prevState) => ({ ...prevState, search: target.value }));
  }, []);

  const toggleFilter = useCallback(
    (item) => () => {
      setState((prevState) => ({
        ...prevState,
        selectedDataFilter: prevState.selectedDataFilter === item ? null : item,
      }));
    },
    [],
  );

  const handleTableDataFilter = useCallback(
    (searchTerm = '', selectedFilter) =>
      (item) => {
        if (isEmpty(item)) return true;

        if (searchTerm) {
          const searchLowerCase = searchTerm.toLowerCase();
          const { name = '', email = '', title = '', status = '' } = item;

          // Combine all searchable fields into an array and filter in one pass
          return [name, email, title, status]
            .filter(Boolean)
            .some((field) =>
              String(field).toLowerCase().includes(searchLowerCase),
            );
        }

        if (!isEmpty(selectedFilter)) {
          const [filterKey, filterValue] = selectedFilter;
          return item[filterKey] === filterValue;
        }

        return true;
      },
    [],
  );

  const currentFilter = useMemo(
    () => handleTableDataFilter(search, selectedDataFilter),
    [handleTableDataFilter, search, selectedDataFilter],
  );

  const filterRow = useMemo(
    () =>
      dataFilter?.map((item) => (
        <div
          key={`filter-${item[0]}-${item[1]}`}
          role="presentation"
          className={`filter ${selectedDataFilter === item ? 'selected' : ''}`}
          onClick={toggleFilter(item)}
        >
          {item[1]}
        </div>
      )),
    [dataFilter, selectedDataFilter, toggleFilter],
  );

  const tableColumns = [
    ['email', 'E-post'],
    ['title', 'Titel'],
    ['name', 'Namn'],
    ['createdAt', 'Registrerad'],
    ['lastLogin', 'Senaste inloggning'],
    ['orgName', 'OrgName'],
    ['telefonnummer', 'Telefonnummer'],
    ['branchId', 'BranchId'],
    ['id', 'Id'],
    ['branchDbPath', 'BranchDbPath'],
  ];

  const handleExportData = useCallback(() => {
    const filteredData = userData.filter(
      handleTableDataFilter(search, selectedDataFilter),
    );
    exportData(filteredData, 'all-users');
  }, [userData, handleTableDataFilter, search, selectedDataFilter]);

  return (
    <>
      <ContentContainer>
        <section>
          <WhiteBox>
            <AdminOrganisationerDataBox>
              <h3>
                <IosCalendarOutline />
                Användare
                <div className="filters">{size(userData)} Användare</div>
              </h3>
              <div>
                <Button
                  primary
                  size="mini"
                  basic
                  content="Ladda ner data"
                  icon="file excel"
                  labelPosition="right"
                  style={{ marginLeft: 'auto' }}
                  onClick={handleExportData}
                />
              </div>
              <div className="horizontal-container horizontal-container-row">
                <div className="singel-flex">
                  <div className="search">
                    <MdSearch />
                    <input
                      className="search-input"
                      maxLength="140"
                      type="search"
                      placeholder="Sök användare"
                      value={search}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </div>
                <div className="singel-flex filter-row">{filterRow}</div>
              </div>
            </AdminOrganisationerDataBox>
            <AccDataTable
              obj={userData}
              columns={tableColumns}
              sort="email"
              uniqKey="email"
              filter={currentFilter}
              footer={false}
            />
          </WhiteBox>
        </section>
      </ContentContainer>
      <SpinnerLoader isLoading={isLoading} />
    </>
  );
};

export default memo(AllUsers);
