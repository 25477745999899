import { useEffect, useState } from 'react';

import { extend, get, isEmpty, keys, sumBy } from 'lodash';

import { REGISTER_TABLE_SUM_ROWS } from '../../configs';
import { handleTableDataTransform } from '../../utils/time-utils';
import { PaginationContainer, TableContainer } from './styles';

const getKeys = (obj) => keys(extend.apply({}, Object.values(obj)));

const totalValueByKey = (dataObj) => {
  const totals = {};

  REGISTER_TABLE_SUM_ROWS.forEach((key) => {
    totals[key] = sumBy(dataObj, (item) => {
      const value = item[key];

      if (typeof value === 'undefined') return 0;
      if (typeof value === 'string') return 0;
      if (typeof value === 'number') return value;

      const parsedValue = parseFloat(value);
      return Number.isNaN(parsedValue) ? 0 : parsedValue;
    });
  });

  return totals;
};

function AccDataTable(props) {
  const {
    obj,
    columns,
    sort,
    columnClick,
    addClass,
    filter,
    uniqKey = 'id',
    footer = true,
    itemsPerPage = 5,
    disablePagination = false,
  } = props;

  const [tableState, setTableState] = useState({
    dataObj: {},
    dataKeys: [],
    dataArray: [],
    sortBy: '',
    sortReverse: false,
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage);

  const { dataKeys, dataObj, dataArray, sortBy, sortReverse } = tableState;

  useEffect(() => {
    if (obj !== dataObj) {
      const dataArray = Array.isArray(obj) ? obj : Object.values(obj);
      setTableState((prev) => ({
        ...prev,
        dataObj: obj,
        dataArray,
        dataKeys: columns || getKeys(obj),
      }));
      // Reset to first page when data changes
      setCurrentPage(1);
    }
  }, [columns, dataObj, obj]);

  useEffect(() => {
    if (sortBy === '' && sort && sortBy !== sort) {
      setTableState((prev) => ({
        ...prev,
        sortBy: sort,
      }));
    }
  }, [sort, sortBy]);

  // Reset to first page when rows per page changes
  useEffect(() => {
    setCurrentPage(1);
    setRowsPerPage(itemsPerPage);
  }, [itemsPerPage]);

  const handleSortClick = (name, currentSortBy) => () => {
    setTableState((prev) => ({
      ...prev,
      sortBy: name,
      sortReverse: currentSortBy === name ? !prev.sortReverse : false,
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setRowsPerPage(value);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  const renderHeaderRow = () => {
    const { dataKeys, sortBy, sortReverse } = tableState;
    return dataKeys?.map((cell) => {
      const name = Array.isArray(cell) ? cell[0] : cell;
      const label = Array.isArray(cell) ? cell[1] : cell;
      return (
        <th
          key={name}
          className={`${sortBy === name ? 'sorted' : ''} ${
            sortBy === name && sortReverse ? 'reversed' : ''
          }`}
          role="presentation"
          onClick={handleSortClick(name, sortBy)}
        >
          {label}
        </th>
      );
    });
  };

  const renderRow = (rowObj) => {
    if (isEmpty(rowObj)) return null;
    return dataKeys?.map((cell) => {
      // this name in table header
      const name = Array.isArray(cell) ? cell[0] : cell;
      const func = get(columnClick, name);

      const dataString = rowObj[name]
        ? handleTableDataTransform(name, rowObj[name])
        : rowObj[name];

      const classes =
        typeof addClass === 'function' ? addClass(dataString) : '';

      return (
        <td
          key={name}
          onClick={(e) => {
            e.preventDefault();
            if (typeof func === 'function') {
              func(rowObj);
            }
          }}
          className={
            typeof func === 'function' ? `hasClick ${classes}` : classes
          }
        >
          {dataString && typeof dataString !== 'object' ? dataString : ''}
        </td>
      );
    });
  };

  const renderFooterRow = (dataObj) => {
    // Use the full filtered data array for calculations, not just the current page
    const totals = totalValueByKey(dataObj);

    const footerRowData = dataKeys?.map((cell) => {
      const name = Array.isArray(cell) ? cell[0] : cell;
      return (
        <th key={name} style={{ fontSize: '1rem' }}>
          <b>{totals[name] ? totals[name].toLocaleString('sv-SE') : ''}</b>
        </th>
      );
    });

    footerRowData[0] = (
      <th key="Sammanfattning" style={{ fontSize: '1rem' }}>
        <b>
          <i>Sammanfattning</i>
        </b>
      </th>
    );

    return footerRowData;
  };

  // Pagination controls
  const renderPagination = (totalItems) => {
    const totalPages = Math.ceil(totalItems / rowsPerPage);

    if (totalPages <= 1) return null;

    const pageNumbers = [];
    const maxPageButtons = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

    if (endPage - startPage + 1 < maxPageButtons) {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination-controls">
        <div className="pagination-info">
          Showing {Math.min((currentPage - 1) * rowsPerPage + 1, totalItems)} to{' '}
          {Math.min(currentPage * rowsPerPage, totalItems)} of {totalItems}{' '}
          entries
        </div>
        <div className="pagination-buttons">
          <button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
            className="pagination-button"
          >
            First
          </button>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button"
          >
            Previous
          </button>

          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => handlePageChange(number)}
              className={`pagination-button ${currentPage === number ? 'active' : ''}`}
            >
              {number}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            Next
          </button>
          <button
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            Last
          </button>
        </div>
        <div className="rows-per-page">
          <label htmlFor="rowsPerPage">Rows per page:</label>
          <select
            id="rowsPerPage"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
    );
  };

  let filteredArrayData = [...dataArray];

  if (typeof filter === 'function') {
    filteredArrayData = filteredArrayData?.filter(filter);
  }

  if (sortBy !== '') {
    filteredArrayData.sort((a, b) => {
      if (sortReverse) {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
      return a[sortBy] >= b[sortBy] ? 1 : -1;
    });
  }

  // Calculate pagination
  const totalItems = filteredArrayData.length;
  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  // Only paginate the displayed data if pagination is enabled, otherwise show all data
  const currentItems = disablePagination
    ? filteredArrayData
    : filteredArrayData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="acc-data-table-wrapper">
      <TableContainer className="acc-data-table">
        <table>
          <thead>
            <tr>{renderHeaderRow()}</tr>
          </thead>
          {currentItems.length > 0 && (
            <tbody>
              {currentItems?.map((rowData, index) => (
                <tr key={`${rowData[uniqKey]}-${index}`}>
                  {renderRow(rowData)}
                </tr>
              ))}
            </tbody>
          )}
          {footer && (
            <tfoot>
              <tr>{renderFooterRow(filteredArrayData)}</tr>
            </tfoot>
          )}
        </table>
      </TableContainer>
      {!disablePagination && (
        <PaginationContainer>
          {renderPagination(totalItems)}
        </PaginationContainer>
      )}
    </div>
  );
}

export default AccDataTable;
